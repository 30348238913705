<template>
  <div
    class="fixed outline-none z-[9999] cursor-pointer"
    :style="style"
    tabindex="0"
    @blur="$emit('close')"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      left: this.x,
      top: this.y,
      show: false,
    };
  },
  computed: {
    style() {
      return {
        top: this.top + 'px',
        left: this.left + 'px',
      };
    },
  },
  watch: {
    x(newX) {
      this.left = newX;
      this.$nextTick(this.reposition);
    },
    y(newY) {
      this.top = newY;
      this.$nextTick(this.reposition);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.reposition();
      this.$el.focus();
    });
  },
  methods: {
    reposition() {
      const menu = this.$el.getBoundingClientRect();
      const { innerWidth, innerHeight } = window;

      if (menu.right > innerWidth) {
        this.left = Math.max(innerWidth - menu.width, 0);
      }
      if (menu.bottom > innerHeight) {
        this.top = Math.max(innerHeight - menu.height, 0);
      }
    },
  },
};
</script>
